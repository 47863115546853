.logo {
  width: 75%;
}

.smartedge_logo {
  position: absolute;
  bottom: 1rem;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-height: 600px) {
  .smartedge_logo {
    display: none;
  }
}
